<template>
  <eden-container>
    <eden-table-actions :title="title" :show-search="false">
      <template slot="actions">
        <eden-periods
          :default-period="'thisweek'"
          :custom-periods="periods"
          :show-custom-period="true"
          @set-period="getUnresolvedIssues"
        />
      </template>
    </eden-table-actions>
    <template v-if="pageData.length">
      <el-table :data="pageData">
        <el-table-column width="200">
          <template #header>
            <span>Issue</span>
          </template>
          <template v-slot="scope">
            <issue-title
              :id="scope.row.id"
              :title="scope.row.title"
              :description="scope.row.description || ''"
              :item="scope.row.order_specifics"
              :comments="scope.row.comments?.length"
              :priority="scope.row.priority"
            />
          </template>
        </el-table-column>
        <el-table-column>
          <template #header>
            <span>Status</span>
          </template>
          <template v-slot="scope">
            <el-tag :type="setType(scope.row.status)">{{
              formatText(scope.row.status?.replace("-", " "))
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="180">
          <template slot="header">
            <span>Order type</span>
          </template>
          <template slot-scope="scope">
            <p>
              {{ unformatSlug(scope.row.order_type) }}
            </p>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <span>Category</span>
          </template>
          <template slot-scope="scope">
            <p>
              {{ scope.row?.issue_category?.toString() }}
            </p>
          </template>
        </el-table-column>
        <el-table-column width="200">
          <template slot="header">
            <span>Department</span>
          </template>
          <template slot-scope="scope">
            <p>
              {{ scope.row.department }}
            </p>
          </template>
        </el-table-column>
        <el-table-column width="120">
          <template slot="header">
            <span>Customer</span>
          </template>
          <template slot-scope="scope">
            <issue-customer
              :type="'customer'"
              :customers="
                [scope.row.affected_user] == null
                  ? []
                  : [scope.row.affected_user]
              "
              :gardener="
                scope.row.gardener == null ? '' : scope.row.gardener.name
              "
            />
          </template>
        </el-table-column>
        <el-table-column width="160">
          <template slot="header">
            <span>Date</span>
          </template>
          <template slot-scope="scope">
            <span class="font-sm">{{
              formatDate(scope.row.created_at, "do m, y")
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </template>
    <div v-else>
      <p class="empty-indicator font-sm text-grey-tertiary">
        There are no unresolved Laundry issues. Great job!
      </p>
    </div>
  </eden-container>
</template>

<script>
import dashboard from "@/requests/dashboard/cleaning";
import IssueTitle from "@/components/Feedback/Issues/Issue/Structure/IssueTitle";
import IssueCustomer from "@/components/Feedback/Issues/Issue/Structure/IssueCustomer";
export default {
  name: "CleaningIssuesReported",
  components: {
    IssueTitle,
    IssueCustomer,
  },
  data() {
    return {
      loading: false,
      defaultPeriod: "thisweek",
      periods: {
        lastweek: "Last week",
        yesterday: "Yesterday",
        today: "Today",
        thisweek: "This week",
      },
      page: 1,
      pageData: [],
    };
  },
  computed: {
    title() {
      return `${this.pageData.length} Unresolved Issues`;
    },
  },
  created() {
    this.getUnresolvedIssues({ period: this.defaultPeriod });
  },
  methods: {
    getUnresolvedIssues({ period, from, to }) {
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      dashboard
        .issues(start_date, end_date)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.pageData = data;
            this.defaultPeriod = period;
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
