import axios from "axios";

export default {
  orders(start_date, end_date) {
    return axios.get(
        `crm/service-dashboard/cleaning/orders/overview?start_date=${start_date}&end_date=${end_date}`
    );
  },

  roomType(start_date, end_date) {
    return axios.get(
        `crm/service-dashboard/cleaning/orders/room-type/count?start_date=${start_date}&end_date=${end_date}`
    );
  },

  feedback(start_date, end_date) {
    return axios.get(
        `crm/service-dashboard/cleaning/feedback-summary?start_date=${start_date}&end_date=${end_date}`
    );
  },

  issues(start_date, end_date) {
    return axios.get(
        `crm/service-dashboard/cleaning/orders/unresolved-issues?start_date=${start_date}&end_date=${end_date}`
    );
  },
};