<template>
  <div>
    <el-row type="flex">
      <el-col :span="24">
        <eden-page-stats
          class="my-0"
          :loading="loading"
          :title="'Overview'"
          :stats="stats"
          :show-periods="true"
          :periods="periods"
          @set-period="getStats"
        />
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="24">
        <dashboard-service-feedback :service="'cleaning'" />
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="24">
        <cleaning-areas-metrics />
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="24">
        <cleaning-issues-reported />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import CleaningIssuesReported from "@/components/Dashboard/Cleaning/CleaningIssuesReported";
import DashboardServiceFeedback from "@/components/Dashboard/DashboardServiceFeedback";
import CleaningAreasMetrics from "@/components/Dashboard/Cleaning/CleaningAreasMetrics";
import dashboard from "@/requests/dashboard/cleaning";

export default {
  name: "DashboardCleaning",
  components: {
    CleaningIssuesReported,
    DashboardServiceFeedback,
    CleaningAreasMetrics,
  },
  data() {
    return {
      loading: false,
      overviewPeriod: "today",
      overview: {},
      periods: {
        today: "Today",
        lastweek: "Last week",
        thismonth: "This month",
      },
    };
  },
  computed: {
    stats() {
      const overview = this.overview
      return [
        {
          label: "Total cleaning subscribers",
          figure: this.formatFigure(overview.total_cleaning_subscribers),
        },
        {
          label: "Subscribers served",
          figure: this.formatFigure(overview.total_subscribers_served),
        },
        {
          label: "One-time customers served",
          figure: this.formatFigure(overview.total_one_time_orders_served),
        },
        {
          label: "Orders completed",
          figure: this.formatFigure(overview.total_orders),
        },
        {
          label: "One-time orders",
          figure: this.formatFigure(overview.total_one_time_orders),
        },
        {
          label: "Avg. time spent cleaning",
          figure: overview.average_cleaning_time,
        },
        {
          label: "Rooms cleaned",
          figure: this.formatFigure(overview.total_rooms_cleaned),
        },
        {
          label: "Standard cleaning orders",
          figure: this.formatFigure(overview.total_standard_cleaning),
        },
        {
          label: "Deep cleaning orders",
          figure: this.formatFigure(overview.total_deep_cleaning),
        },
        {
          label: "Post-construction orders",
          figure: this.formatFigure(overview.total_post_construction_cleaning),
        },
      ];
    },
  },
  created() {
    this.getStats({ period: this.overviewPeriod });
  },
  methods: {
    getStats({ period, from, to }) {
      this.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      dashboard
        .orders(start_date, end_date)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.overview = data;
            this.overviewPeriod = period;
            this.loading = false;
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  &:not(:last-child) {
    margin-bottom: 50px !important;
  }
}
</style>
