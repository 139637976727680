<template>
  <eden-container>
    <eden-table-actions :title="'Cleaned Rooms Breakdown'" :show-search="false">
      <template slot="actions">
        <eden-periods
          :default-period="'thisweek'"
          :custom-periods="periods"
          :show-custom-period="true"
          @set-period="setPeriod"
        />
      </template>
    </eden-table-actions>
    <el-table :data="areas">
      <el-table-column>
        <template slot="header">
          <span>Room</span>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">{{ scope.row.area }}</span>
        </template>
      </el-table-column>
      <el-table-column>
        <template slot="header">
          <span>Tally</span>
        </template>
        <template slot-scope="scope">
          <span class="font-sm">{{ scope.row.tally }}</span>
        </template>
      </el-table-column>
    </el-table>
  </eden-container>
</template>

<script>
import dashboard from "@/requests/dashboard/cleaning";
export default {
  name: "CleaningAreasMetrics",
  data() {
    return {
      loading: false,
      defaultPeriod: "thisweek",
      periods: {
        lastweek: "Last week",
        yesterday: "Yesterday",
        today: "Today",
        thisweek: "This week",
      },
      pageData: [], 
    };
  },
  computed: {
    areas() {
      return [
        {
          area: "Bedroom",
          slug: "bedroom",
          tally: this.pageData?.bedrooms !== null ? this.pageData?.bedrooms : 0,
        },
        {
          area: "Living Rooms / Dining Area",
          slug: "living-rooms-dining-area",
          tally: this.pageData.living_rooms_dining_areas !== null ? this.pageData.living_rooms_dining_areas : 0,
        },
        {
          area: "Toilet / Bathroom",
          slug: "bathrooms",
          tally:
            this.pageData?.bathrooms !== null ? this.pageData?.bathrooms : 0,
        },
        {
          area: "Kitchen",
          slug: "kitchen",
          tally: this.pageData?.kitchen !== null ? this.pageData?.kitchen : 0,
        },
        {
          area: "Study / Store",
          slug: "study",
          tally: this.pageData?.study !== null ? this.pageData?.study : 0,
        },
        {
          area: "Outdoor / Balcony",
          slug: "balcony",
          tally: this.pageData?.balcony !== null ? this.pageData?.balcony : 0,
        },
      ];
    },
  },
  created() {
    this.getBreakdown({ period: this.defaultPeriod });
  },
  methods: {
    setPeriod() {
      //
    },
    getBreakdown({ period, from, to }) {
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });
      dashboard
        .roomType(start_date, end_date)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.pageData = data;
            this.defaultPeriod = period;
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
